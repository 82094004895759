import { theInvoice, request } from "./main";
let vehiclesLoaded = false;
let isFourWheeler = false;
let isThirdPartyInsurance = false;
let jobId = null;
$(document).ready(function () {
    $(".nepali-datepicker").nepaliDatePicker({
        dateFormat: "%y-%m-%d",
        closeOnDateSelect: true,
    });

    if ($("#registrationForm").length) {
        fetchLocations("locations", function (locations) {
            populateCity(locations);
        });
    }
    $("#vehicleSeats").hide();
});

$("#vehicleType").on("change", function () {
    let vehicleType = $("#vehicleType option:selected").val();
    isFourWheeler = vehicleType === "four-wheeler" ? true : false;
    handleSeatsInput();
});

$("#thirdPartyInsurance").on("change", function () {
    isThirdPartyInsurance = $("#thirdPartyInsurance").is(":checked");
    handleSeatsInput();
});

function handleSeatsInput() {
    if (isThirdPartyInsurance && isFourWheeler) {
        $("#vehicleSeats").show();
    } else {
        $("#seats").val("");
        $("#vehicleSeats").hide();
    }
}

$("#comprehensiveButton").on("click", function () {
    if (!vehiclesLoaded) {
        fetchVehicleData("brands?type=1", function (data) {
            populateBrands(data);
        });
        vehiclesLoaded = true;
    }
    $("#comprehensive-insurance-modal").modal("show");
});
$("input[type=radio][name=vehicle_type]").change(function () {
    fetchVehicleData(`brands?type=${this.value}`, function (brands) {
        populateBrands(brands);
    });
});

function fetchVehicleData(url, cb) {
    request({
        type: "get",
        url: `vehicles/${url}`,
        onSuccess: (result) => cb(result.data),
    });
}

function populateBrands(brands) {
    $("#brand").empty();
    brands.map((brand) => {
        $("#brand").append(
            $("<option></option>")
                .attr("value", brand.slug)
                .text(_.capitalize(brand.title))
        );
    });
    populateModels(_.head(brands).slug);
}

function getVehicleType() {
    return $("input[type='radio'][name='vehicle_type']:checked").val();
}

function populateModels(brand) {
    $("#model").empty();
    fetchVehicleData(
        `models?type=${getVehicleType()}&brand=${brand}`,
        function (models) {
            models.map((model) => {
                $("#model").append(
                    $("<option></option>")
                        .attr("value", model.slug)
                        .text(_.capitalize(model.title))
                );
            });
            populateVariants(_.head(models).slug);
        }
    );
}

$("#brand").on("change", function () {
    if (this.value !== "") {
        populateModels(this.value);
    }
});

$("#model").on("change", function () {
    let model = this.value;
    if (model !== "") {
        populateVariants(model);
    }
});

function populateVariants(model) {
    let selectedBrand = $("#brand option:selected").val();
    $("#variant").empty();
    fetchVehicleData(
        `variants?type=${getVehicleType()}&brand=${selectedBrand}&model=${model}`,
        function (variants) {
            variants.map((variant) => {
                $("#variant").append(
                    $("<option></option>")
                        .attr("value", variant.id)
                        .text(_.capitalize(variant.title))
                );
            });
        }
    );
}

function fetchLocations(url, cb) {
    request({
        type: "get",
        url,
        onSuccess: function (locations) {
            locations = locations.data;
            cb(locations);
        },
    });
}

$("#taxCalculator").on("submit", function (e) {
    e.preventDefault();

    var formData = new FormData(_.head($(this)));
    request({
        type: "post",
        url: "web-calculator",
        data: formData,
        onSuccess: onCalculationSuccess,
        loadingButton: "#calculateButton",
        onError: (error) => {
            swal("Sorry!", Object.values(error.errors)[0][0], "error");
            $("#renewalFrom").focus();
        },
    });
});

$("#taxcalculator").on("hidden.bs.modal", function () {
    _.head($("#taxCalculator")).reset();
    isThirdPartyInsurance = false;
    isFourWheeler = false;
    handleSeatsInput();
});

function onCalculationSuccess(response) {
    $("#taxcalculator").modal("hide");
    const { data } = response;
    let template = Handlebars.compile(theInvoice);
    let templateData = template({
        unpaidTax: data.unpaid_tax,
        unpaidTaxPenalty: data.penalty,
        renewalCharge: data.renewal_charge,
        insuranceAmount: data.insurance,
        serviceCharge: data.service_charge,
        tax: data.current_tax,
        total: data.total,
        renewalFine: data.renewal_fine,
        modelTaxAmount: data.model_tax_amount,
        modelTaxFine: data.model_tax_fine,
    });

    $("#invoice").html(templateData);
    $("#invoice").modal("show");
}

function populateCity(locations) {
    locations.map((location) => {
        $("#city").append(
            $("<option></option>")
                .attr("value", location.id)
                .text(_.capitalize(location.title))
        );
    });
}

$("#city").on("change", function () {
    if (this.value == 0) {
        $("#area").empty();
        $("#area").append(
            $("<option></option>").attr("value", "").text("Select Area")
        );
    } else {
        fetchLocations(`locations?selected=${this.value}`, function (areas) {
            populateArea(areas);
        });
    }
});

function populateArea(areas) {
    $("#area").empty();
    areas.map((area) => {
        $("#area").append(
            $("<option></option>")
                .attr("value", area.id)
                .text(_.capitalize(area.title))
        );
    });
}

$("#registrationForm").on("submit", function (e) {
    e.preventDefault();
    var formData = new FormData(_.head($(this)));

    request({
        type: "post",
        url: "renewal-order",
        data: formData,
        onSuccess: (data) => onOrderSuccess(data),
        loadingButton: "#registerButton",
    });
});

function onOrderSuccess(response) {
    _.head($("#registrationForm")).reset();
    $("#vehicle-renewal-modal").modal("hide");
    swal("Thank you!", response.message, "success");
}

$("#trackRenewalForm").on("submit", function (e) {
    e.preventDefault();
    request({
        type: "post",
        url: "orders/track",
        data: new FormData(_.head($(this))),
        onSuccess: ({ data }) => {
            const wrapper = document.createElement("div");
            wrapper.innerHTML = `
            <p>Tracking Code: ${data.trackingCode}</p>
            <p>Status: ${data.status}</p>
            <p>Owner's Name: ${data.ownerName}</p>
            <p>Vehicle Number: ${data.vehicleNumber}</p>
            <p>Bluebook Expiry Date: ${data.expiryDate}</p>
            <p>Bluebook Collection Date: ${data.collectedDate}</p>
            <p>Estimated Delivery Date: ${data.estimatedRenewalDate} 
            
            `;
            swal({
                title: "Order Status",
                content: wrapper,
            });
        },

        loadingButton: "#orderButton",
        onError: (response) => {
            swal("Error!", response.message, "error");
        },
    });
});

$("#fullName").on("keyup", function (e) {
    const isOwner = $("#isOwner").is(":checked");
    const $ownerName = $("#ownerName");
    const fullName = $("#fullName").val();
    if (isOwner) {
        $ownerName.val(fullName);
    }
});
let ownerName = "";

$("#ownerName").on("keyup", function (e) {
    if (!$("#isOwner").is(":checked")) {
        ownerName = $("#ownerName").val();
    }
});
$("#isOwner").on("change", function () {
    const isOwner = $(this).is(":checked");
    const fullName = $("#fullName").val();
    const $ownerName = $("#ownerName");
    if (isOwner) {
        $ownerName.val(fullName);
        $ownerName.attr("readonly", true);
    } else {
        $ownerName.val(ownerName);
        $ownerName.attr("readonly", false);
    }
});

$("#comprehensiveInsuranceForm").on("submit", function (e) {
    e.preventDefault();
    request({
        type: "post",
        url: "comprehensive-insurance-order",
        data: new FormData(_.head($(this))),

        onSuccess: (data) => {
            swal("Success!", data.message, "success");
            $("#comprehensive-insurance-modal").modal("hide");
            _.head($("#comprehensiveInsuranceForm")).reset();
        },
        loadingButton: "#comrehensiveButton",
        onError: (data) => {
            swal("Error!", data.message, "error");
        },
    });
});

$(".apply-button").on("click", function () {
    jobId = $(this).data("id");
    const title = $(this).data("title");
    $("#job-title").text(title);
});

$("#job-form").on("submit", function (e) {
    e.preventDefault();
    request({
        type: "post",
        url: `career/${jobId}/apply`,
        data: new FormData(_.head($(this))),

        onSuccess: (data) => {
            swal("Success!", data.message, "success");
            $("#job-modal").modal("hide");
            _.head($("#job-form")).reset();
        },
        loadingButton: "#apply-button",
        isLocalUrl: true,
        onError: (data) => {
            swal("Error!", data.message, "error");
        },
    });
});
